import React from 'react';

export default function HomePage () {
	return (
	<>
		<h1>Yup, This is the Home Page</h1>
		<p>Paragraph One</p>
	</>
	);
}
