// command: npm create-react-app my-blog
// command: npm install react-router-dom
// command: npm install history
// command: npm start

import './App.css';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ArticlePage from './pages/ArticlePage';
import ArticlesList from './pages/ArticlesList';
import NavBar from './NavBar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

function App() {
  return (
	<Router>
    <div className="App">
		<NavBar />
		<div id="page-body">
		<Routes>
			<Route exact path="/" element={<HomePage />}/>
			<Route path="/about" element={<AboutPage />}/>
			<Route path="/article/:name" element={<ArticlePage />}/>
			<Route path="/list" element={<ArticlesList />}/>
		</Routes>
		</div>
    </div>
	</Router>
  );
}

export default App;
