import React from 'react';

export default function AboutPage () {
	return (
	<>
		<h1>Yup, This is the About Page</h1>
		<p>Paragraph One</p>
	</>
	);
}
